import React from "react";
import SEO from "../components/seo";
import Main from "../components/main";

const NotFoundPage = () => (
  <>
    <SEO title="404: Not found" />
    <Main title={"?"} notFound />
  </>
);

export default NotFoundPage;
